@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';
@use '../../../../../../styles/mixins';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 17px;
  margin-bottom: 20px;
}

.icons {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
  margin-bottom: 20px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    gap: 10px;
  }
}

.linkContainer {
  text-align: center;
}

.socialMediaButton {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 1;
  text-decoration: none;

  path {
    fill: var(--modal-background);
  }

  @include mixins.button-hover;

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: 30px;
    width: 30px;
  }
}

.fontAwesomeIcon {
  color: var(--modal-icon-color);
  font-family: FontAwesome, serif;
  font-size: 35px;
}

.copyLink {
  align-items: center;
  display: flex;
  text-decoration: none;
  transition: 0.2s filter;
  width: 100%;

  @media (max-width: breakpoints.$breakpoint-sm) {
    flex-direction: column;
  }
}

.shareLink {
  border-spacing: 0;
  border-style: inset;
  display: flex;
  flex-grow: 1;
  font-size: 12px;
  overflow: hidden;
  padding: 10px;
  text-align: center;
  text-overflow: ellipsis;
  width: 286px;
}
