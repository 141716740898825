@use '../../../../styles/breakpoints';

.sceneSelectorContainer {
  align-content: center;
  background: var(--menu-button-background);
  border-radius: 90px;
  color: var(--menu-button-color);
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
  justify-content: space-between;
  padding: 10px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sceneSelector {
  display: flex;
}

.sceneList {
  background: var(--carousel-tabs-background);
  border-radius: 12px;
  bottom: -60px;
  color: var(--menu-button-color);
  left: 30px;
  max-height: 400px;
  overflow-x: hidden;
  padding: 10px 0;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  z-index: 5;

  @media (max-width: breakpoints.$breakpoint-md) and (orientation: landscape) {
    max-height: 350px;
  }
}

.sceneListOpen::-webkit-scrollbar {
  display: none;
}

.sceneSelectorArrow {
  align-self: center;
  color: var(--menu-button-color);
  height: 10px;
}

.sceneSelectorArrowUp {
  transform: rotate(180deg);
}

.dropdownItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 43px;
  justify-content: space-between;
  line-height: 43px;
  overflow: hidden;
  padding: 0 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.dropdownItem:hover {
  background-color: rgba(0, 0, 0, 15%);
}

.activeDropdownItem,
.activeDropdownItem:hover {
  background-color: rgba(0, 0, 0, 30%);
}

.selectedPanoTitle {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
