@use '../../../../styles/breakpoints';

.container {
  bottom: 0;
  pointer-events: all;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.carousel {
  align-items: center;
  background-color: var(--carousel-background);
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 3px;
  white-space: nowrap;
}

.arrows {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 5;

  svg {
    cursor: pointer;
    pointer-events: all;
  }
}

.controls {
  display: flex;
}

.pills {
  display: inline-block;
  margin-left: 20px;
  margin-top: 6px;
}

.item {
  cursor: pointer;
  display: inline-block;
  height: 100px;
  margin: 6px 3px;
  min-width: 200px;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.active {
  border: 3px solid var(--carousel-active-border-color);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.close {
  align-items: center;
  align-self: flex-end;
  background-color: var(--carousel-background);
  border-radius: 5px 5px 0 0;
  color: var(--carousel-controls-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 9px 30px;
  position: absolute;
  transform: translateX(-50%);
  transition: 0.2s transform;
  width: 45px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    justify-content: center;
    padding: 10px 0 7px;
    width: 50px;
  }
}

.caret {
  fill: var(--carousel-controls-color);
  transform: rotate(90deg);
  transition: 0.3s all;
}

.caretOpen {
  transform: rotate(-90deg);
  transition: 0.3s all;
}

.title {
  align-items: center;
  bottom: 0;
  color: var(--carousel-title-color);
  display: flex;
  height: 24px;
  justify-content: space-around;
  position: absolute;
  width: 100%;

  > span {
    bottom: 0;
    color: var(--carousel-title-color);
    max-width: 180px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    z-index: 3;
  }

  &::before {
    background-color: var(--carousel-title-background);
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.floorPlanIndicator {
  position: absolute;
  right: 4px;
  top: 4px;
}
