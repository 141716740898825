@use 'sass:color';
@use '../../../../styles/variables';
@use '../../../../styles/breakpoints';

.buttons {
  text-align: center;

  button {
    background-color: var(--button-background);
    border: none;
    border-radius: var(--button-border-radius);
    color: var(--text-color);
    cursor: pointer;
    margin: 0 0.5rem;
    padding: 0.5rem;
    width: 7rem;

    &:hover {
      filter: brightness(0.8);
    }
  }
}

.container {
  align-items: center;
  backdrop-filter: blur(5px);
  background-color: color.adjust(variables.$black, $alpha: -0.5);
  color: variables.$white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: center;
  pointer-events: all;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.logo {
  max-width: 250px;
  object-fit: contain;
}

.poweredBy {
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    margin-top: 20px;
    max-width: 80px;

    @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
      max-height: 10vh;
    }
  }
}

.welcomeMessage {
  margin: 16px 0;
  text-align: center;
}
