@use '../../../../../../styles/breakpoints';
@use '../../../../../../styles/variables';

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  div,
  span {
    text-align: center;
  }
}

.address {
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    flex-grow: 1;
  }
}

.phone {
  color: inherit;
  text-decoration: none;
}

.logo {
  margin-bottom: 35px;
  width: 160px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    margin-bottom: 30px;
    width: 125px;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    margin-bottom: 0;
    width: 110px;
  }
}

.description {
  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 14px;
  }
}
