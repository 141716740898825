@use '../../../../styles/variables';
@use '../../../../styles/breakpoints';

.zoomGroup {
  margin-bottom: 16px;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    width: auto;
  }
}
