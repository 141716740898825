@use '../../../styles/variables';

.accessibilityStatement {
  align-items: center;
  color: variables.$white;
  display: flex;
  font-size: 14px;
  gap: 4px;
  justify-content: center;
  margin-top: 30px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
