@use '../../../../../styles/variables';
@use '../../../../../styles/breakpoints';

.icon {
  margin-bottom: 10px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    margin: 0;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  position: absolute;

  @media (max-width: breakpoints.$breakpoint-sm) {
    right: 0;
  }
}

.button {
  background-color: var(--menu-button-background);
  border-radius: 5px;
  border-style: none;
  color: var(--menu-button-color);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  padding: 10px;
  text-align: center;
  text-decoration: none;

  @media (max-width: breakpoints.$breakpoint-sm) {
    margin-bottom: 10px;
    padding: 15px;
  }
}
