@use '../../styles/breakpoints';
@use '../../styles/variables';
@use '../../styles/mixins';

.container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.modal {
  background-color: var(--modal-background);
  border-radius: 8px;
  box-shadow: variables.$box-shadow;
  color: var(--modal-color);
  max-height: calc(var(--app-height) - 40px);
  max-width: 800px;
  overflow: auto;
  padding: 80px 100px;
  position: relative;
  width: 100%;

  @media (max-width: breakpoints.$breakpoint-sm) {
    max-width: calc(100vw - 40px);
    padding: 40px;
    width: 640px;
  }
}

.close {
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 25px;
  top: 25px;

  path {
    fill: var(--modal-color);
  }

  @include mixins.button-hover;

  @media (max-width: breakpoints.$breakpoint-sm) {
    right: 15px;
    top: 15px;
  }
}
