@use '../../styles/mixins';

.closeButton {
  background-color: var(--menu-button-background);
  border-radius: 100%;
  color: var(--menu-button-color);
  font-size: 2rem;
  padding: 0 14px;
  position: absolute;
  right: 1.3rem;
  top: 1.2rem;

  @include mixins.button-hover($brightness: 1.25);
}
