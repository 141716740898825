.templateDrawer {
  height: 70vh;
  width: 100%;
}

.closeDark {
  path {
    fill: #111;
  }
}
