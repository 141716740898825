@use '../../../../styles/variables';
@use '../../../../styles/breakpoints';

$menu-item-border-radius: 90px;

.menu {
  align-items: center;
  display: flex;
}

.menuItem {
  align-items: center;
  background-color: var(--menu-button-background);
  color: var(--menu-button-color);
  cursor: pointer;
  display: flex;
  height: 45px;
  justify-content: center;
  padding: 0 15px;
  transition: 0.2s background-color;

  &:first-child {
    border-bottom-left-radius: $menu-item-border-radius;
    border-top-left-radius: $menu-item-border-radius;
    padding-left: 25px;
  }

  &:last-child {
    border-bottom-right-radius: $menu-item-border-radius;
    border-top-right-radius: $menu-item-border-radius;
    padding-right: 25px;
  }

  &:hover:not(.menuActive) {
    color: var(--menu-icon-color-hover);
    transition: 0.2s color;
  }
}

.menuActive {
  background-color: var(--menu-item-active-background);
  color: var(--menu-icon-color-active);
}

.fontAwesomeIcon {
  font-family: FontAwesome, serif;
  font-size: 18px;
}
