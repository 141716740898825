@use 'sass:color';
@use '../../styles/variables';
@use '../../styles/breakpoints';
@use '../../styles/mixins';

.buttons {
  text-align: center;

  button {
    background-color: var(--button-background);
    border: none;
    border-radius: var(--button-border-radius);
    color: var(--text-color);
    font-size: 1rem;
    margin: 0 1rem;
    padding: 1rem;
    width: 10rem;

    @include mixins.button-hover;
  }
}

.container {
  align-items: center;
  backdrop-filter: blur(5px);
  background-color: color.adjust(variables.$black, $alpha: -0.5);
  color: variables.$white;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  justify-content: center;
  pointer-events: all;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  @media (max-width: breakpoints.$breakpoint-sm) {
    gap: 20px;
  }

  @media (max-height: 650px) {
    gap: 10px;
  }

  @media (max-height: 400px) {
    gap: 0;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    gap: 0;
    justify-content: flex-start;
  }
}

.logo {
  height: auto;
  max-height: 35vh;
  min-width: 200px;
  object-fit: contain;
  width: 20%;
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    gap: 0;
    padding: 10px;
  }
}

.poweredBy {
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    margin-top: 20px;
    max-width: 100px;

    @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
      max-height: 10vh;
    }
  }
}

.welcomeMessage {
  margin: 16px 0;
  text-align: center;
}
