@use '../../../../styles/breakpoints';
@use '../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    align-items: flex-end;
  }
}

.mobileTopContainer {
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    display: flex;
    grid-gap: 10px;
  }
}

.menuContainer {
  z-index: 1;
}
