@use '../../styles/breakpoints';

$width-size: 66px;

.carousel-container {
  background-color: var(--pill-background);
  border-radius: var(--button-border-radius);
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 4px;
  position: relative;
  width: min-content;
}

.carousel-pill {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 4px 8px;
  white-space: nowrap;
  z-index: 3;
}

.carousel-active {
  background-color: var(--pill-background-active);
  border-radius: var(--button-border-radius);
  padding: 4px 8px;
  position: absolute;
  z-index: 2;
}

.scene-container {
  background-color: var(--pill-background);
  border-radius: 60px;
  display: flex;
  justify-content: center;
  z-index: 4;
}

.scene-pill {
  align-items: center;
  background-color: var(--pill-icon-inactive);
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 4px 8px;
  white-space: nowrap;
  z-index: 4;

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: 32px;
    width: 38px;
  }
}

.scene-active {
  background-color: var(--pill-background-active);
  border: 0.8px solid var(--pill-background);
  border-radius: 60px;
  padding: 4px 8px;
  position: absolute;
  z-index: 4;
  @media (max-width: breakpoints.$breakpoint-sm) {
    width: 32px;
  }
}

.pro-container {
  background-color: var(--pill-background);
  border: 1px var(--pill-icon-inactive) solid;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  padding: 2px;
  width: 198px;
  z-index: 4;
}

.pro-pill {
  align-items: center;
  background-color: var(--pill-icon-inactive);
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  white-space: nowrap;
  width: $width-size;
  z-index: 4;

  svg {
    width: 17px;
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: 32px;
    width: 38px;
  }
}

.pro-active {
  background-color: var(--pill-background-active);
  border-radius: 60px;
  display: flex;
  justify-content: center;
  position: absolute;
  width: $width-size;
  z-index: 4;
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    width: 32px;
  }
}

[class*='-pill'] .tooltip {
  background-color: var(--carousel-background);
  border-radius: 6px;
  color: var(--carousel-title-color);
  padding: 5px 0;
  position: absolute;
  text-align: center;
  top: -37px;
  visibility: hidden;
  width: $width-size;
}

[class*='-pill'] .tooltip::after {
  border-color: var(--carousel-background) transparent transparent;
  border-style: solid;
  border-width: 5px;
  content: '';
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 100%;
}

[class*='-pill']:hover .tooltip {
  visibility: visible;
}
