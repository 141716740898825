@use '../../../../../../styles/variables';

.hotspotDot {
  cursor: pointer;
  position: absolute;

  circle {
    fill: var(--menu-background);
    stroke: variables.$white;
    stroke-width: 2px;
  }
}
