.carouselVisibleVideo.carouselVisibleVideo {
  box-shadow: 0 75px 0 0 black;
  height: calc(100vh - 165px);
  transition: height 0.6s ease-in-out;
}

.carouselHiddenVideo.carouselHiddenVideo {
  box-shadow: 0 75px 0 0 black;
  height: calc(100vh - 50px);
  transition: height 0.3s ease-in-out;
}
