.app {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  height: 100%;
  width: 100%;
}

button {
  font-family: var(--font-family);
}

.viewport {
  flex-grow: 1;

  // allows children to arrange themselves with position: absolute;
  position: relative;

  > * {
    pointer-events: all;
  }
}
