@use '../../../../styles/variables';
@use '../../../../styles/breakpoints';

.container {
  align-items: center;
  background-color: rgba(255, 255, 255, 37%);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;

  svg {
    max-height: 16px;
  }

  > * {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.autoRotate.autoRotate {
  border-radius: 0;
  height: 25px;
  margin-bottom: 0;
  width: auto;

  > div {
    background-color: transparent;
    height: auto;
    margin: auto !important;
    min-height: 0;
    min-width: 0;
    padding: 0;
    width: auto;
  }

  path {
    fill: #fff;
  }

  line {
    stroke: #fff;
  }
}

.floorPlanMobileControl {
  cursor: pointer;
  margin-bottom: 5px;
}
