@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  color: var(--modal-color);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mobileLandscape {
  flex-direction: row;
}

.topContent {
  flex-basis: 50%;
}

.bottomContent {
  display: flex;
  flex-basis: 50%;
  justify-content: center;
}

.unitName {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.price {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.floorplanImage {
  align-self: center;
  margin: 0 auto;
  max-height: 200px;
}

.unitTourInfo.unitTourInfo {
  color: var(--modal-color);
}
