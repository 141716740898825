@use '../../styles/breakpoints';
@use '../../styles/variables';
@use '../../styles/mixins';

.map {
  height: 100vh;
  pointer-events: all;
  position: absolute;
  width: 100vw;
  z-index: 0;
}

@mixin theme-colors {
  background-color: var(--menu-background);
  border-radius: var(--button-border-radius);
  color: var(--menu-icon-color-default);

  svg,
  path {
    fill: var(--menu-icon-color-default);
  }
}

.directions.directions {
  @include theme-colors;

  align-items: center;
  display: flex;
  font-size: 1rem;
  gap: 0.5rem;
  justify-content: center;
  margin: 1rem 0;
  padding: 0.5rem;
  text-decoration: none;

  svg {
    margin-bottom: 2px;
  }

  &:hover {
    filter: brightness(0.9);
    transition: 0.2s filter;
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 14px;
    padding: 10px 20px;
  }
}

.close {
  @include theme-colors;
  @include mixins.button-hover;

  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  left: 64px;
  padding: 12px;
  position: absolute;
  top: 20px;
  z-index: 400;

  label {
    font-family: var(--font-family), sans-serif;
    font-size: 16px;
  }

  // if we're not on the legacy theme, the button will overlap with Share Live on small screens
  // so here we reposition it and don't show the big label
  @media (max-width: breakpoints.$breakpoint-sm) {
    &:not([data-theme='tb-legacy']) {
      left: 10px;
      top: 140px;

      label {
        display: none;
      }
    }
  }
}
