$primary-blue: #8fceff;
$dark-blue: #5fa2dd;
$blue: #81bcf8;
$middle-blue: #a8cff8;
$light-blue: #cfe4f8;

$background-blue: #f0f8ff;

$red: #f76c51;
$dark-red: #fc5351;
$light-red: #fd9d9e;

$green: #9cc96b;
$purple: #ab9efb;
$orange: #fbc3a2;
$white: #fff;
$black: #000;
$dark-blue-grey: #32404e;
$dark-grey: #333;
$grey: #7a7a7a;

$font-color: $dark-grey;
