@use '../../styles/variables';

$floor-plan-hide-icon-size: 25px;

.floorPlan {
  display: flex;
  gap: 10px;
  left: 20px;
  position: absolute;
  top: 20px;
  z-index: 0;
}
