@use '../../../../styles/variables';

.hotspot {
  opacity: 0.6;
  position: absolute;

  &:hover {
    opacity: 0.9;
  }
}

.activeHotspot {
  opacity: 0.9;
}
