@use '../../../../styles/breakpoints';

.container {
  bottom: 10px;
  left: 12.5%;
  pointer-events: all;
  position: absolute;
  right: 12.5%;
  z-index: 1;
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.carousel.carousel {
  align-items: center;
  display: flex;
  margin: 0 40px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 8px 0 30px;
  white-space: nowrap;
  width: calc(100% - 80px);
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    background-color: var(--carousel-background);
    bottom: 0;
    margin-left: 0;
    padding: 4px 0 26px;
    width: 100%;
  }
}

.arrows {
  align-items: center;
  background-color: var(--carousel-background);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  opacity: 0.8;
  pointer-events: none;
  position: absolute;
  width: 100%;

  svg {
    cursor: pointer;
    pointer-events: all;
  }
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    background-color: transparent;
    z-index: 1;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    margin-left: 10px;
  }
}

.pills {
  display: inline-block;
  margin-bottom: 4px;
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    margin-bottom: 6px;
  }
}

.item {
  cursor: pointer;
  display: inline-block;
  height: 100px;
  margin: 6px 3px;
  min-width: 200px;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.item:first-of-type {
  margin-left: 0;
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    margin-left: 6px;
  }
}

.item:last-of-type {
  margin-right: 0;
  @media (max-width: breakpoints.$breakpoint-sm) {
    margin-right: 6px;
  }
}

.active {
  border: 4px solid var(--carousel-title-color);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.close {
  align-items: center;
  align-self: flex-end;
  background-color: var(--pill-background-active);
  border-radius: 10px 10px 0 0;
  color: var(--carousel-controls-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 26px;
  left: calc(50% - 33.5px);
  padding-top: 3px;
  position: absolute;
  transform: rotate(0);
  transition: 0.2s transform;
  width: 67px;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    left: calc(100% - 75px);
    width: 54px;
  }
}

.showArrow {
  transform: rotate(180deg);
}

.title {
  align-items: center;
  bottom: -24px;
  color: var(--carousel-title-color);
  display: flex;
  height: 24px;
  justify-content: space-around;
  position: absolute;
  width: 100%;

  > span {
    bottom: 0;
    color: var(--carousel-title-color);
    max-width: 180px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    z-index: 3;
  }

  &::before {
    background-color: var(--carousel-title-background);
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.floorPlanIndicator {
  position: absolute;
  right: 4px;
  top: 4px;
}

.slide.slide {
  width: 180px;
}
