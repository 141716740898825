@use '../../styles/variables';

.shareLive {
  align-items: center;
  background-color: var(--carousel-tabs-background-active);
  border-radius: var(--button-border-radius);
  color: var(--carousel-tabs-icon-color-active);
  display: flex;
  justify-content: center;
  left: 50%;
  padding: 10px 20px;
  position: absolute;
  text-decoration: none;
  text-wrap: nowrap;
  top: 20px;
  transform: translate(-50%, 0);
  transition: 0.2s background-color;
  user-select: none;
  z-index: 1;

  &:hover {
    background-color: var(--share-live-background-hover);
  }
}

.shareLiveIcon {
  margin-bottom: -2px;
  margin-right: 2px;

  path {
    fill: var(--carousel-tabs-icon-color-active);
  }
}
