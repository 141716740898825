@use '../../../../styles/breakpoints';

.bottomBar {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 80px;
  left: 20px;
  margin-bottom: 15px;
  pointer-events: none; // ensures clicks on empty space are passed to the viewer
  position: absolute;
  right: 20px;
  z-index: 1;

  @media (max-width: breakpoints.$breakpoint-sm) {
    grid-template-rows: 60px 80px;
    left: 10px;
    right: 10px;
  }

  // makes sure the overlay still has interactivity
  > * {
    pointer-events: initial;
  }
}

.singleRow {
  grid-template-rows: 80px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    grid-template-rows: 60px;
  }
}

.topRow {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.bottomRow {
  align-items: center;
  border-radius: 10px;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 16px;
}

.compactLayout {
  justify-content: space-around;
}

.bottomRowLeftContent {
  display: flex;
  gap: 16px;
}

.bottomRowRightContent {
  display: flex;
  gap: 16px;
}

.shareLive {
  align-self: center;
  background: var(--menu-button-background);
  border-radius: 90px;
  color: var(--menu-button-color);
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  left: initial;
  padding: 0 20px;
  position: relative;
  top: initial;
  transform: initial;
  transition: 0.2s background;

  svg {
    margin-right: 8px;

    path {
      fill: var(--menu-button-color);
    }
  }

  &:hover {
    background: var(--menu-button-background);
    color: var(--menu-icon-color-hover);
    transition: 0.2s color;

    svg {
      path {
        fill: var(--menu-icon-color-hover);
        transition: 0.2s fill;
      }
    }
  }
}

.topRowSceneSelector {
  justify-self: flex-start;
  width: 160px;
}

.bottomRowSceneSelector {
  width: 200px;
}
