@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  margin-bottom: 30px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 16px;
  }
}

.icons {
  display: flex;
  grid-gap: 30px;
  margin-bottom: 30px;
}

.icon {
  align-items: center;
  background-color: var(--modal-icon-color);
  border-radius: var(--button-border-radius);
  cursor: pointer;
  display: flex;
  height: 75px;
  justify-content: center;
  width: 75px;

  path {
    fill: var(--modal-background);
  }

  &:hover {
    background-color: var(--modal-icon-color-hover);
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: 60px;
    width: 60px;
  }
}

.linkContainer {
  display: flex;
  gap: 1rem;

  @media (max-width: breakpoints.$breakpoint-sm) {
    align-items: center;
    flex-direction: column;
  }
}

.link {
  background-color: var(--modal-icon-color);
  border-radius: var(--button-border-radius);
  color: var(--modal-background);
  font-size: 14px;
  padding: 15px 20px;
  user-select: text;
  white-space: nowrap;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 12px;
    white-space: normal;
  }
}

.button {
  background-color: var(--modal-icon-color);
  border: none;
  border-radius: var(--button-border-radius);
  color: var(--modal-background);
  cursor: pointer;
  font-size: 1rem;
  height: 54px;
  width: 10rem;

  &:hover {
    background-color: var(--modal-icon-color-hover);
  }
}
