@use '../../../../../styles/breakpoints';
@use '../../../../../styles/variables';

.templateDrawer {
  height: 60vh;

  @media (max-width: variables.$breakpoint-sm) {
    height: 100%;
  }
}
