@use '../../../../styles/breakpoints';
@use '../../../../styles/variables';

.close {
  align-items: center;
  background-color: var(--menu-background);
  border-radius: 3px 0 0 3px;
  color: var(--carousel-controls-color);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 75px;
  justify-content: center;
  left: -23px;
  opacity: 1;
  position: absolute;
  transition: right 0.2s;
  width: 23px;

  @media (max-width: variables.$breakpoint-sm) {
    margin: 0 20px 0 auto;
  }

  @media (max-width: variables.$breakpoint-sm) and (orientation: landscape) {
    font-size: 11px;
  }
}

.hamburger {
  animation: pulsate 3s infinite;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 27px;
  padding-top: 1px;
  transform: rotate(-90deg);
}

@keyframes pulsate {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
