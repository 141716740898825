.dropdown {
  background-color: var(--menu-button-background);
  border-radius: 8px;
  bottom: 100%;
  margin-bottom: 10px;
  max-width: 200px;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  width: 100vw;
}

.dropdownBackground {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.menuButton {
  align-items: center;
  color: var(--menu-button-color);
  display: flex;
  gap: 10px;
  padding: 10px 0;

  svg {
    max-width: 18px;

    path {
      fill: var(--menu-button-color);
    }

    circle,
    line {
      stroke: var(--menu-button-color);
    }
  }
}
