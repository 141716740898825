@use '../../styles/variables';
@use '../../styles/breakpoints';

.imageContainer {
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.image {
  width: 100%;
}

.hotspotContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    flex-direction: row;
  }
}
