@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.close {
  background-color: var(--modal-background);
  border-radius: 100%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 15%);
  cursor: pointer;
  height: 40px;
  padding: 10px;
  position: absolute;
  right: 35px;
  top: 25px;
  width: 40px;

  path {
    fill: var(--modal-color);
    transition: 0.2s fill;
  }

  &:hover {
    path {
      fill: #808080;
      transition: 0.2s fill;
    }
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    right: 5px;
    top: 5px;
    transform: scale(0.5);
  }
}
