@use '../../../../styles/breakpoints';

.unitTourInfo {
  align-items: center;
  color: var(--carousel-tabs-icon-color-default);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  gap: 1rem;
  text-wrap: nowrap;
}

.divider {
  border-right: 1px solid;
  height: 100%;
}

.price {
  @media (max-width: breakpoints.$breakpoint-md) {
    font-size: 22px;
  }
}

.perMonth {
  font-size: 10px;

  @media (max-width: breakpoints.$breakpoint-md) {
    font-size: 12px;
  }
}

.asLowAs {
  font-size: 10px;
}

.details {
  display: grid;
  grid-gap: 5px 10px;
  grid-template-areas:
    'beds-and-baths unit-size .'
    'floorplan-type unit-number building-name';
}

.joinPrimaryDetails {
  grid-template-areas:
    'primary-details primary-details primary-details'
    'floorplan-type unit-number building-name';
  justify-items: center;
}

.primaryDetails {
  grid-area: primary-details;
}

.bedsAndBaths {
  grid-area: beds-and-baths;
}

.unitSize {
  grid-area: unit-size;
}

.floorplanType {
  grid-area: floorplan-type;
}

.unitNumber {
  grid-area: unit-number;
}

.buildingName {
  grid-area: building-name;
}

.secondaryDetails {
  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 10px;
  }
}
