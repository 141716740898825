@use '../../../../../../../styles/variables';
@use '../../../../../../../styles/breakpoints';

.container {
  color: var(--modal-color);
  display: flex;
  flex-direction: column;
  max-width: 337px;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-width: 100%;
  }
}

.mobileLandscape {
  flex-direction: row;
}

.topContent {
  flex-basis: 50%;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    grid-column: 1;
    grid-row: 1;
  }
}

.unitInfo {
  align-items: start;
  display: flex;
  justify-content: flex-start;
}

.unitInfo p {
  font-size: 14px;
  font-weight: 600;
  margin: 0 10px;
}

.bottomContent {
  display: flex;
  flex-basis: 50%;
  justify-content: center;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    grid-column: 2;
    grid-row: 1 / span 2;
  }
}

.unitName {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.floorplanImage {
  align-self: center;
  margin: 0 auto;
  max-height: 200px;
}

.unitTourInfo.unitTourInfo {
  color: var(--modal-color);
}

.unitFeatures {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 35px;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    grid-column: 1;
    grid-row: 2;
  }
}

.heading {
  font-size: 1rem;
  font-weight: 600;
  line-height: 23px;
}

.featuresList {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 26px;
  text-transform: capitalize;
}

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: 32px;

  > button {
    align-items: center;
    background-color: var(--menu-button-background);
    border-radius: 6px;
    border-style: none;
    color: var(--text-color);
    display: flex;
    height: 34px;
    justify-content: center;
    width: 40%;
  }

  path {
    fill: var(--text-color);
  }

  svg {
    margin-right: 8px;
  }
}
