@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  margin-bottom: 40px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 16px;
  }
}

.address {
  margin-bottom: 30px;
  text-align: center;
}

.actions {
  align-items: center;
  display: flex;

  svg {
    margin-right: 5px;
  }

  path {
    fill: var(--modal-background);
  }
}

.action {
  align-items: center;
  background-color: var(--modal-icon-color);
  border: none;
  border-radius: var(--button-border-radius);
  color: var(--modal-background);
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  margin: 0 1rem;
  padding: 1rem;
  text-decoration: none;
  width: 10rem;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 14px;
    padding: 10px 20px;
    width: auto;
  }

  &:hover {
    background-color: var(--modal-icon-color-hover);
  }
}
