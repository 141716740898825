@use '../../styles/variables';
@use '../../styles/breakpoints';

.container {
  background-color: variables.$black;
  height: 100%;
  width: 100%;

  @media (max-width: variables.$breakpoint-sm) {
    align-items: center;
    background-color: variables.$grey;
    display: flex;
    justify-content: center;
  }
}

.videoContainer {
  height: 100%;
  width: 100%;

  @media (max-width: variables.$breakpoint-sm) and (orientation: portrait) {
    height: auto;
  }
}
