@use '../../../../../styles/variables';
@use '../../../../../styles/breakpoints';

.iconContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.container {
  align-items: center;
  background-color: var(--carousel-background);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 51px;
  justify-content: center;
  position: absolute;
  right: 26px;
  top: 26px;
  width: 51px;
}

.firstLine,
.secondLine,
.thirdLine {
  background-color: var(--menu-icon-color-default);
  height: 2px;
  left: 0;
  transition: 0.3s all;
  width: 25px;
}

.secondLine {
  margin: 5px 0;
}
