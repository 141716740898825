@use '../../../../styles/breakpoints';
@use '../../../../styles/variables';

.container {
  border-radius: var(--button-border-radius);
  display: flex;
  gap: 2px;
  justify-content: space-between;
  margin-left: 5px;
  padding: 0;
  position: relative;
  width: min-content;
}

.tab {
  align-items: center;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  justify-content: space-around;
  padding: 3px 8px 0;
  white-space: nowrap;
  width: min-content;
}

.active {
  border-radius: var(--button-border-radius);
  padding: 4px 8px;
  position: absolute;
  z-index: 2;
}

.icon-pano,
.icon-image,
.icon-video {
  color: currentcolor;
  font-family: icomoon, sans-serif;
  font-size: 19px;
  width: 25px;
}

.icon-pano::before {
  content: '\e60b';
}

.icon-image::before {
  content: '\e615';
}

.icon-video::before {
  content: '\e618';
}
