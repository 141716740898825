@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 25px;

  path {
    fill: #afafaf;
    transition: 0.2s fill;
  }

  &:hover {
    path {
      fill: #808080;
      transition: 0.2s fill;
    }
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    right: 5px;
    top: 5px;
    transform: scale(0.5);
  }
}
