@use '../../../../../styles/variables';
@use '../../../../../styles/breakpoints';

.container {
  background-color: var(--menu-background);
  border-radius: var(--controls-border-radius);
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  overflow: auto;
  -ms-overflow-style: none;
  position: relative;
  scrollbar-width: none;

  div,
  span {
    color: var(--menu-icon-color-default);
  }

  rect,
  path {
    fill: var(--menu-icon-color-default);
  }

  circle,
  line {
    stroke: var(--menu-icon-color-default);
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    --control-size: 50px;

    margin: 0 0 20px 10px;
    max-width: 60%;
    padding: 5px 0;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    margin: 0 0 0 15px;
    max-width: 25%;
    padding: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.menuButton {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: 23px;
    width: 23px;
  }
}

.label {
  font-size: 9px;
  margin-top: 5px;
}

.arrow {
  align-items: center;
  background-color: var(--menu-background);
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  transition: 0.2s opacity;
  width: var(--control-size);
  z-index: 1;

  &:hover {
    filter: brightness(0.8);
  }
}

.arrowVisible {
  opacity: 1;
  pointer-events: all;
  transition: 0.2s opacity;
}

.arrowUp {
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;

  > div {
    top: 0;
  }
}

.arrowDown {
  bottom: 0;
  left: 0;
  position: sticky;
  width: 100%;

  > div {
    bottom: 0;
  }
}

.control.control {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  padding: 10px;
  text-align: center;
  transition: 0.2s filter;

  &:hover {
    transition: 0.2s all;

    div,
    span {
      color: var(--menu-icon-color-hover);
    }

    rect,
    path {
      fill: var(--menu-icon-color-hover);
    }

    circle,
    line {
      stroke: var(--menu-icon-color-hover);
    }
  }

  &:active {
    transition: 0.2s all;

    div,
    span {
      background-color: var(--menu-icon-color-active);
    }
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    padding: 0 10px;
  }

  &:only-child {
    @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
      border-radius: var(--controls-border-radius);
    }
  }
}

.fontAwesomeIcon {
  font-family: FontAwesome, serif;
  font-size: 18px;
}
