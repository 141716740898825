@use '../../styles/variables';

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global {
  .colors-debugger-higlight {
    border: 2px solid red !important;
    box-shadow: 0 0 15px 5px rgba(255, 255, 0, 75%) !important;
    text-decoration: underline !important;
    transition: all 0.5s !important;
  }
}

.colorsDebugger {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 20px;
  padding: 20px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;

  div {
    align-items: center;
    display: flex;
  }
}

.select {
  padding: 10px;
}

.colorExample {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 25%);
  display: inline-block;
  height: 20px;
  margin-left: 20px;
  width: 20px;
}
