$button-size: 40px;

.wrapper {
  align-items: center;
  background-color: var(--carousel-title-background);
  border-radius: 100%;
  color: var(--carousel-title-color);
  display: inline-flex;
  height: $button-size;
  justify-content: center;
  opacity: 0.7;
  width: $button-size;
}
