@use '../../styles/variables';
@use '../../styles/breakpoints';

.controlGroup {
  margin-bottom: 10px;
}

.iconContainer {
  height: 10px;
  position: relative;
  width: 15px;
}

.firstLine,
.secondLine,
.thirdLine {
  background-color: var(--menu-icon-color-default);
  height: 2px;
  left: 0;
  position: absolute;
  transition: 0.3s all;
  width: 15px;
}

.firstLine {
  top: 0;
}

.secondLine {
  top: 5px;
}

.thirdLine {
  top: 10px;
}

.visible {
  .firstLine {
    transform: translateY(5px) rotate(45deg);
  }

  .secondLine {
    opacity: 0;
  }

  .thirdLine {
    transform: translateY(-5px) rotate(-45deg);
  }
}
