@use '../../../../styles/breakpoints';

.viewControls {
  bottom: 85px;
  display: flex;
  gap: 16px;
  position: absolute;
  right: 0;

  @media (max-width: breakpoints.$breakpoint-sm) {
    bottom: initial;
    gap: 4px;
    position: relative;
    right: initial;
  }
}
