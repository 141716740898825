@use '../../../../styles/breakpoints';
@use '../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-right: 35px;
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 10;
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    padding-right: 20px;
  }
}

.mobileTopContainer {
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    display: flex;
    grid-gap: 10px;
  }
}

.menuContainer {
  margin-bottom: 100px;
  z-index: 1;
}
