@use '../../../../../../../styles/variables';
@use '../../../../../../../styles/breakpoints';

.container {
  color: var(--modal-color);
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 16px;
  }
}

.address {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  gap: 10px;
  margin-bottom: 30px;

  a {
    color: var(--modal-color);

    &:visited,
    &:focus {
      color: var(--modal-color);
    }
  }
}

.actions {
  align-items: center;
  display: flex;

  svg {
    margin-right: 5px;
  }

  path {
    fill: var(--modal-background);
  }
}

.action {
  align-items: center;
  background-color: var(--modal-icon-color);
  border: none;
  border-radius: 5px;
  color: var(--modal-background);
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  margin-right: 1rem;
  padding: 0.5rem;
  text-decoration: none;
  width: 10rem;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 14px;
    padding: 10px 20px;
    width: auto;
  }

  &:hover {
    background-color: var(--modal-icon-color-hover);
  }
}
