@use '../../../../styles/breakpoints';
@use '../../../../styles/variables';

.container {
  align-items: center;
  bottom: 100px;
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: center;
  margin-left: 4px;
}
