@use '../../../../styles/variables';

.floorPlanMap {
  opacity: 0.8;
  position: relative;
}

.mapImage {
  max-height: 298px;
  max-width: 298px;
  user-select: none;
  vertical-align: middle;
}

.maximized {
  transform: scale(1.9);
  transform-origin: 0 0;
  transition: transform 0.4s ease-in-out;
}

.minimized {
  transform: scale(1);
  transform-origin: 0 0;
  transition: transform 0.4s ease-in-out;
}
