@use '../../../../../../styles/breakpoints';
@use '../../../../../../styles/variables';

.container {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    display: flex;
    gap: 60px;
    margin-right: 100px;
  }
}

.header {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    align-items: center;
  }

  div,
  span {
    letter-spacing: 1px;
    text-align: center;
  }
}

.accessibility {
  margin-bottom: 20px;

  > a {
    color: variables.$blue;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    margin: 0;
  }
}

.address {
  display: flex;
  flex-direction: column;

  a {
    color: var(--modal-color);

    &:focus,
    &:visited {
      color: var(--modal-color);
    }
  }
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    margin-bottom: 0;
    white-space: nowrap;
  }
}

.phone {
  color: inherit;
  text-decoration: none;
}

.logo {
  height: auto;
  margin-bottom: 35px;
  min-width: 150px;
  width: 30%;

  @media (max-width: breakpoints.$breakpoint-sm) {
    margin-bottom: 30px;
    width: 10%;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    margin-bottom: 0;
  }
}

.description {
  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 16px;
    font-weight: 600;
  }
}
