.menuDialog {
  background-color: var(--modal-background);
  border-radius: 4px;
  bottom: 90px;
  min-width: 300px;
  padding: 20px 30px;
  position: fixed;
  z-index: 10;
}

.menuDialogMobile {
  bottom: 0;
  left: 0;
  padding-top: 60px;
  right: 0;
  top: 0;
  z-index: 10;
}

.close {
  cursor: pointer;
  height: 14px;
  position: absolute;
  right: 25px;
  top: 20px;
  width: 14px;

  path {
    fill: #7a7a7a;
  }
}
