@use '../../../../styles/breakpoints';
@use '../../../../styles/z-indexes';

.row {
  display: flex;
}

.container {
  bottom: 0;
  pointer-events: all;
  position: absolute;
  width: 100%;
  z-index: z-indexes.$legacy-carousel;
}

.carousel {
  align-items: center;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
  white-space: nowrap;
}

.controls {
  border: none;
}

.arrows {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: z-indexes.$legacy-carousel-arrow;

  svg {
    cursor: pointer;
    pointer-events: all;
  }
}

.item {
  cursor: pointer;
  display: inline-block;
  height: 90px;
  margin: 5px 5px 5px 0;
  min-width: 180px;
  position: relative;

  &:first-child {
    margin-left: 5px;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.title {
  align-items: center;
  bottom: 0;
  color: var(--carousel-title-color);
  display: flex;
  height: 26px;
  justify-content: space-around;
  position: absolute;
  transition: 0.2s bottom;
  width: 100%;

  > span {
    align-items: center;
    bottom: 0;
    color: var(--carousel-title-color);
    display: flex;
    font-size: 11.62px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 1px;
    max-width: 180px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    z-index: 3;
  }

  &::before {
    background-color: var(--carousel-title-background);
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.active {
  border: 4px solid var(--carousel-active-border-color);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;

  ~ .title {
    bottom: 4px;
    transition: 0.2s bottom;
  }
}

.accessibilityActive {
  border: 4px solid green;
}

.floorPlanIndicator {
  position: absolute;
  right: 4px;
  top: 4px;
}

.slide.slide {
  width: 180px;
}
