@use '../../../../../../styles/breakpoints';
@use '../../../../../../styles/variables';

.container {
  align-items: center;
  color: var(--modal-color);
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
}

.title {
  font-size: 17px;
  font-weight: 700;
  line-height: 17px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 16px;
  }
}

.address {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 30px;
  text-align: center;

  a {
    color: var(--modal-color);

    &:visited,
    &:focus {
      color: var(--modal-color);
    }
  }
}

.actions {
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    margin-right: 5px;
  }

  path {
    fill: currentcolor;
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    flex-direction: column;
  }
}

.action {
  align-items: center;
  background-color: var(--modal-icon-color);
  border: none;
  border-radius: 2px;
  color: var(--modal-icon-color-hover);
  cursor: pointer;
  display: flex;
  font-size: 12px;
  justify-content: center;
  margin: 0 0.25rem;
  padding: 0.5rem;
  text-decoration: none;
  text-transform: uppercase;
  width: 130px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 14px;
    margin-top: 15px;
    padding: 10px 20px;
    width: 200px;
  }

  &:hover {
    background-color: var(--modal-icon-color-hover);
    color: var(--menu-icon-color-default);
  }
}

.fontAwesomeIcon {
  font-family: FontAwesome, serif;
  font-size: 13px;
  margin-right: 0.3rem;
}
