@use '../../styles/breakpoints';

.mediaVideo.mediaVideo {
  box-shadow: 0 150px 0 0 black;
  height: calc(100vh - 90px);
  transition: height 0.6s ease-in-out;

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: calc(100vh - 140px);
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    height: calc(100vh - 60px);
  }
}

.closeMedia {
  right: 40px;
}
