@use '../../../../../../styles/variables';

$hotspot-x-offset: -23px;
$hotspot-y-offset: -47px;

.hotspotCone {
  cursor: pointer;
  left: $hotspot-x-offset;
  position: absolute;
  top: $hotspot-y-offset;
  transform-origin: 29px 53px;

  path {
    fill: var(--menu-background);
    opacity: 0.9;
    stroke: variables.$white;
    stroke-width: 2px;
  }
}
