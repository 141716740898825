$button-size: 40px;

.wrapper {
  align-items: center;
  background-color: #4c4c4c;
  border-radius: 100%;
  display: inline-flex;
  height: $button-size;
  justify-content: center;
  width: $button-size;

  &:hover {
    background-color: #333;
  }
}

.arrow {
  color: #fff;
  width: 24px;

  &:hover {
    background-color: #333;
  }
}
