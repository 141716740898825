.container {
  box-shadow: none;
}

.icon {
  height: 24px;
  width: 32px;
}

.off {
  display: none;
}
