@use '../../../../styles/variables';

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global {
  .rc-slider-track {
    background-color: var(--carousel-tabs-background-active);
    border: 1px solid var(--carousel-tabs-icon-color-active);
    cursor: pointer;
  }

  .rc-slider-rail {
    background-color: var(--carousel-tabs-icon-color-active);
    border: 1px solid var(--carousel-tabs-icon-color-active);
    cursor: pointer;
  }

  .rc-slider-handle {
    background-color: var(--carousel-tabs-icon-color-active);
    border-color: var(--carousel-tabs-icon-color-active);
    border-width: 3px;
    opacity: 1;
    z-index: 2;

    &:hover {
      border-color: var(--carousel-tabs-icon-color-active);
    }
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    background-color: var(--carousel-tabs-background-active);
    border-color: var(--carousel-tabs-icon-color-active);
    box-shadow: none;
  }
}

.slider {
  height: 100%;
  position: relative;
}

.sliderText {
  color: var(--carousel-tabs-icon-color-active);
  font-size: 8px;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  white-space: nowrap;
  z-index: 1;
}
