@use '../../../../styles/breakpoints';
@use '../../../../styles/variables';

.button {
  align-items: center;
  color: var(--menu-icon-color-default);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0 5px;

  &:hover {
    color: var(--menu-icon-color-hover);
  }
  @media (max-width: variables.$breakpoint-sm) and (orientation: landscape) {
    padding: 6px 5px;
  }
}

.menuButtons {
  overflow-x: visible;
  overflow-y: scroll;
}

.text {
  font-size: 12px;
  font-variant: small-caps;
  font-weight: bold;
  text-align: center;
  @media (max-width: variables.$breakpoint-sm) and (orientation: landscape) {
    font-size: 11px;
  }
  @media (max-width: variables.$breakpoint-sm) {
    font-size: 13px;
  }
}

.icon-tour-info,
.icon-book-now,
.icon-menu,
.icon-share,
.icon-contact,
.icon-arrow-left a,
.icon-arrow-right a,
.icon-floorplan,
.icon-campus,
.icon-grid,
.icon-image,
.icon-pano,
.icon-video,
.icon-fullscreen,
.icon-closefullscreen {
  &::before {
    font-family: icomoon, sans-serif;
  }
}

.icon-tour-info::before {
  content: '\e608';
}

.icon-book-now::before {
  content: '\e603';
}

.icon-menu::before {
  content: '\e614';
}

.icon-share::before {
  content: '\e60f';
}

.icon-contact::before {
  content: '\e61b';
}

.icon-arrow-left {
  font-size: 20px;
}

.icon-arrow-left a::before {
  content: '\e60c';
}

.icon-arrow-right {
  font-size: 20px;
}

.icon-arrow-right a::before {
  content: '\e60d';
}

.icon-grid {
  display: inline-block;
  font-size: 20px;
  padding-top: 11px;
}

.icon-grid::before {
  content: '\e616';
}

.icon-campus::before {
  content: '\e607';
}

.icon-floorplan::before {
  content: '\e606';
}

.icon-image::before {
  content: '\e615';
}

.icon-pano::before {
  content: '\e60b';
}

.icon-video::before {
  content: '\e618';
}

.icon-fullscreen::before {
  content: '\e61a';
}

.icon-closefullscreen::before {
  content: '\e61c';
}
